import Typography from "typography"
// import Wordpress2016 from "typography-theme-wordpress-2016"
import moragaTheme from "typography-theme-moraga"

moragaTheme.overrideThemeStyles = () => {
  return {
    p: {
      marginBottom: "12px",
    },
    ul: {
      marginBottom: "12px",
    },
    li: {
      marginBottom: "2px",
    },
    a: {
      color: "#3338b8",
      "&:hover, &:focus, &:active": {
        color: "#00046e",
      },
    },
    ".gatsby-resp-image-wrapper": {
      marginTop: `1rem`,
    },
    pre: {
      borderLeft: "2pt solid #607d8b",
      marginLeft: "12pt",
      paddingLeft: "12pt",
    },
    h2: {
      marginBottom: `calc(1.56rem / 2)`,
    },
    h3: {
      marginTop: "48px",
      marginBottom: `24px`,
    },
    h4: {
      color: "black",
      marginBottom: `calc(1.56rem / 3)`,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    h5: {
      marginBottom: `calc(1.56rem / 3)`,
    },
    h6: {
      marginBottom: `calc(1.56rem / 3)`,
    },
  }
}

// delete Wordpress2016.googleFonts
delete moragaTheme.googleFonts

const typography = new Typography(moragaTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
