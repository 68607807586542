// This intermediate file is used for treeshaking
// It allows us to do "import * as Icons" and to then
// access the icons as <Icons[icon] /> without adding all
// possible icons to the bundle.
// https://react-icons.netlify.com/#/
export {
  FaUsers,
  FaRegHandPeace,
  FaPeopleCarry,
  FaBook,
  FaBox,
  FaRegListAlt,
} from "react-icons/fa"

export { GoMegaphone } from "react-icons/go"

export {
  AiOutlineUsergroupAdd,
  AiOutlineBook,
  AiOutlineDotChart,
} from "react-icons/ai"

export { FiActivity, FiBook, FiLayout, FiUsers } from "react-icons/fi"

export {
  IoIosPeople,
  IoIosPaper,
  IoMdPlay,
  IoMdTrendingUp,
  IoIosMegaphone,
  IoIosApps,
} from "react-icons/io"
